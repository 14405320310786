import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Stack,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  styled,
  TableBody,
  Button,
  Tabs,
  Tab
} from '@mui/material';

import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { setLoadingEvent, setEvent } from '../../features/event/eventSlice';

import { doc, onSnapshot } from 'firebase/firestore';
import { store } from '../../config/firebase';
import { useColorMode } from '../../hooks/colorModeProvider';
import PoweredBy from '../ui/poweredBy';
import {
  getEventLeaderboard,
  getEventSectionsLeaderboard
} from '../../features/leaderboard/leaderboardSlice';
import BSCircularProgress from '../ui/progress/circularProgress';
import SectionTotalsCard from '../ui/scoreboard/sectionTotalsCard';
import SectionScoreCard from '../ui/scoreboard/sectionScorecard';
import TotalsCard from '../ui/scoreboard/totalsCard';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderBottom: 'none',
    fontSize: 12
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    borderBottom: 'none'
  }
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const EmbeddedDetailedLogsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const event = useSelector((state) => state.event.event);
  const loading = useSelector((state) => state.event.loading);

  const loadingLeaderboard = useSelector((state) => state.leaderboard.loading);
  const leaderboard = useSelector((state) => state.leaderboard.leaderboard);
  const [selectedTab, setSelectedTab] = React.useState(0);

  useEffect(() => {
    if (event) {
      dispatch(getEventLeaderboard({ eventId: event.id }));
    }
  }, [dispatch, event]);

  const getTeamsByRounds = (section) => {
    const rounds = [];
    for (let i = 0; i < event.gameFormat.rounds; i++) {
      const teams = section.teams.filter((t) => t.round === i + 1);

      if (teams.length > 0) {
        rounds.push({ teams, round: i + 1 });
      }
    }

    return rounds;
  };

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Typography variant="h5" mt={2}>
        Scores
      </Typography>
      {loadingLeaderboard || loading ? (
        <BSCircularProgress caption={'Loading...'} size={30} />
      ) : (
        leaderboard && (
          <Box sx={{ width: '100%' }}>
            <Box display={'flex'} justifyContent={'center'}>
              <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)}>
                {event.publishLogs && <Tab label="Log" value={0} />}
                {event.publishScorecard && <Tab label="Detailed Scorecard" value={1} />}
              </Tabs>
            </Box>

            {event.publishLogs && (
              <CustomTabPanel value={selectedTab} index={0}>
                <Box
                  sx={{ width: '100%' }}
                  display={'flex'}
                  flexDirection={'row'}
                  gap={3}
                  justifyContent={'center'}
                  flexWrap={'wrap'}
                  py={2}>
                  {leaderboard.map((team, index) => (
                    <Fragment key={index}>
                      <TotalsCard team={team} />
                    </Fragment>
                  ))}
                </Box>
              </CustomTabPanel>
            )}
            {event.publishScorecard && (
              <CustomTabPanel value={selectedTab} index={1}>
                {leaderboard.map((team, index) => (
                  <Box key={index} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography variant="h5" my={2}>
                      Scorecard
                    </Typography>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'center'}
                      gap={1}
                      flexWrap={'wrap'}>
                      {getTeamsByRounds(team).map((tr, index) => (
                        <Fragment key={index}>
                          <SectionScoreCard teams={tr.teams} round={tr.round} />
                        </Fragment>
                      ))}
                    </Box>
                  </Box>
                ))}
              </CustomTabPanel>
            )}
          </Box>
        )
      )}
      <PoweredBy />
    </Box>
  );
};

export default EmbeddedDetailedLogsView;
