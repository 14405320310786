import React, { Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  MenuItem,
  Typography,
  CircularProgress,
  Button,
  Fab,
  useTheme,
  useMediaQuery
} from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';

import PrimaryButton from '../ui/buttons/primaryButton';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Select from '../ui/forms/fields/select';
import gameFormatService, { GameType } from '../../features/gameFormat/gameFormatService';
import EventMultipleVs from './eventMultipleVs';
import { addEventGame } from '../../features/event/eventSlice';
import GreenSelector from '../ui/clubControls/greenSelector';
import { createGame } from '../../features/game/gameSlice';
import EventSinglesVs from './eventSinglesVs';
import OverrideGameFormat from '../gameFormats/override';

import AddIcon from '@mui/icons-material/Add';

const schema = yup.object().shape({
  teamA: yup.string().required('Team A is required'),
  teamB: yup.string().required('Team B is required'),
  teamAColor: yup.string(),
  teamBColor: yup.string(),
  teams: yup.object().required('Teams are required'),
  round: yup.number(),
  rink: yup.number(),
  green: yup.string()
});

const AddGameDialog = ({ section, game, fab }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const event = useSelector((state) => state.event.event);
  const addGameLoading = useSelector((state) => state.event.addGameLoading);
  const selectedVenue = useSelector((state) => state.venue.selectedVenue);
  const [gameFormat, setGameFormat] = useState(event.gameFormat);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const errors = methods.formState.errors;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createGameName = (data) => {
    let gameFormat = event.gameFormat;
    let gameName = '';

    if (event.venue && data.green !== 'na') {
      gameName = gameName + `${data.green}${data.rink}`;
    }

    if (gameFormat.sections > 0) {
      gameName = gameName + ` - ${section.name}`;
    }

    if (gameFormat.rounds > 0) {
      gameName = gameName + ` - Round ${data.round}`;
    }

    return gameName;
  };

  const onSubmit = async (data) => {
    let gameName = createGameName(data);
    data.name = gameName;

    if (event) {
      data.event = event.id;
      data.gameFormat = gameFormat;
      data.gameType = gameFormat.gameType;
      data.venue = event.venue.id;
    }

    if (section) {
      data.section = section.id;
    }

    dispatch(createGame(data))
      .unwrap()
      .then(() => {
        setOpen(false);
        methods.reset();
      })
      .catch((err) => {
        //TODO Error
        console.log(err);
      });
  };

  const onAdd = async () => {
    methods.handleSubmit(onSubmit)();
  };

  return (
    <Fragment>
      {!fab ? (
        <PrimaryButton variant={'contained'} onClick={() => handleClickOpen()}>
          Add Game
        </PrimaryButton>
      ) : (
        <Fab color="primary" aria-label="add" onClick={() => handleClickOpen()}>
          <AddIcon />
        </Fab>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'} fullScreen={isMobile}>
        <DialogTitle>Add Game</DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            {/* {event.gameFormat.gameType === GameType.SINGLES ? (
              <EventSinglesVs />
            ) : ( */}
            <EventMultipleVs />

            <GreenSelector event={event} />
            {event.gameFormat.rounds > 0 && (
              <Select name="round" control={methods.control} label={'Round'}>
                {(() => {
                  let container = [];
                  for (let i = 0; i < event.gameFormat.rounds; i++) {
                    container.push(
                      <MenuItem key={i} value={i + 1}>
                        Round {i + 1}
                      </MenuItem>
                    );
                  }
                  return container;
                })()}
              </Select>
            )}
            <Typography variant="h6" my={2}>
              Game Information
            </Typography>
            <Typography>Game Format: {event.gameFormat?.name}</Typography>
            <Typography>
              Game Type: {gameFormatService.getGameTypeString(event.gameFormat?.gameType)}
            </Typography>
          </FormProvider>
          <OverrideGameFormat gameFormat={gameFormat} onChange={(gf) => setGameFormat(gf)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {addGameLoading ? (
            <CircularProgress />
          ) : (
            <PrimaryButton onClick={() => onAdd()} variant={'contained'}>
              Add
            </PrimaryButton>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AddGameDialog;
