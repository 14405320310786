import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import playerService from './playerService';

export const uploadPlayers = createAsyncThunk('player/uploadPlayers', async (data, thunkAPI) => {
  return await playerService
    .uploadPlayers(data.fileName, data.eventId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const addPlayerToEvent = createAsyncThunk(
  'player/addPlayerToEvent',
  async (data, thunkAPI) => {
    return await playerService
      .addPlayerToEvent(data.eventId, data.player)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const updatePlayerFromEvent = createAsyncThunk(
  'player/updatePlayerFromEvent',
  async (data, thunkAPI) => {
    return await playerService
      .updatePlayerFromEvent(data.eventId, data.player)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const removePlayerFromEvent = createAsyncThunk(
  'player/removePlayerFromEvent',
  async (data, thunkAPI) => {
    return await playerService
      .removePlayerFromEvent(data.eventId, data.playerId)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const playerSlice = createSlice({
  name: 'players',
  initialState: {
    player: null,
    players: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadPlayers.fulfilled, (state, action) => {
        state.uploadingPlayers = false;
        state.players = action.payload;
      })
      .addCase(uploadPlayers.rejected, (state, action) => {
        state.uploadingPlayers = false;
        state.players = [];
      })
      .addCase(uploadPlayers.pending, (state, action) => {
        state.uploadingPlayers = true;
        state.players = [];
      })
      .addCase(addPlayerToEvent.fulfilled, (state, action) => {
        state.addingPlayer = false;
        state.players = action.payload;
      })
      .addCase(addPlayerToEvent.rejected, (state, action) => {
        state.addingPlayer = false;
        state.players = [];
      })
      .addCase(addPlayerToEvent.pending, (state, action) => {
        state.addingPlayer = true;
        state.players = [];
      })
      .addCase(updatePlayerFromEvent.fulfilled, (state, action) => {
        state.updatingPlayer = false;
        state.players = action.payload;
      })
      .addCase(updatePlayerFromEvent.rejected, (state, action) => {
        state.updatingPlayer = false;
        state.players = [];
      })
      .addCase(updatePlayerFromEvent.pending, (state, action) => {
        state.updatingPlayer = true;
        state.players = [];
      })
      .addCase(removePlayerFromEvent.fulfilled, (state, action) => {
        state.removingPlayer = false;
        state.players = action.payload;
      })
      .addCase(removePlayerFromEvent.rejected, (state, action) => {
        state.removingPlayer = false;
        state.players = [];
      })
      .addCase(removePlayerFromEvent.pending, (state, action) => {
        state.removingPlayer = true;
        state.players = [];
      });
  }
});

export default playerSlice.reducer;
