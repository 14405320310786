import axiosInstance from '../axiosInstance';

const uploadPlayers = async (fileName, eventId) => {
  return axiosInstance.post('/player/upload', { fileName, eventId }).then((response) => {
    return response.data;
  });
};

const addPlayerToEvent = async (eventId, player) => {
  return axiosInstance.post(`/player/add/${eventId}`, player).then((response) => {
    return response.data;
  });
};

const updatePlayerFromEvent = async (eventId, player) => {
  return axiosInstance.put(`/player/update/${eventId}`, player).then((response) => {
    return response.data;
  });
};

const removePlayerFromEvent = async (eventId, playerId) => {
  return axiosInstance.delete(`/player/remove/${eventId}/${playerId}`).then((response) => {
    return response.data;
  });
};

const playerService = {
  uploadPlayers,
  addPlayerToEvent,
  removePlayerFromEvent,
  updatePlayerFromEvent
};

export default playerService;
